import numeral from 'numeraljs';
import moment from "moment/moment";

export  function requireNonNull<T> (value:T | null | undefined):T{
    if (value === null || value === undefined) {
        throw new Error("Value cannot be null or undefined")
    }
    return value;
}

export default function URLParams():{[key:string]:string | null}{
    const l_search_params:{[key:string]:string | null} = {}
    const query_string = window.sancus?.query?.query_string || (window.location.search.length >0 && window.location.search.substring(1))



    if (query_string) {
        query_string.split("&").forEach((token) => {
            const idx = token.indexOf('=')
            const key = decodeURIComponent(idx != -1 ? token.substring(0, idx) : token);
            const value = idx != -1 ? decodeURIComponent(token.substring(idx + 1)) : null;
            l_search_params[key] = value;
        }, {})
    }

    if (window.sancus?.query) {
        Object.keys(window.sancus.query).forEach((key) => {
            if (key !== "query_string" && window.sancus?.query[key] != null) {
                l_search_params[key] = window.sancus.query[key];
            }
        })
    }

    return l_search_params;
}


export function hashCode(str){
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const character = str.charCodeAt(i);
        hash = ((hash<<5)-hash)+character;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}


export function parsePercentage(type:string | undefined,defaultFormat?:string):string | undefined{
    if (type != null && type.startsWith("percentage")) {
        if (defaultFormat == null) {
            defaultFormat = "##.##"
        }

        const hasArgs = type.indexOf('(') > "percentage".length;
        if (hasArgs) {
            const begin = type.indexOf('(');
            const end = type.indexOf(')');
            const argString = type.substring(begin + 1, end).trim();
            const args = argString.split(',');
            let [format] = args;
            format = format || defaultFormat
            return format
        }else{
            return defaultFormat
        }
    } else {
        return defaultFormat
    }

}

export function formatNumber(value:any, format:string | undefined):string{
    try {
        if (format == null) {
            return value
        }
        if (value == null || value == "") {
            return value
        } else {
            if (format == "" || format == "none") {
                return `${value} %`
            } else if (format == "number") {
                //remove zeros up from
                try {
                    const _numeral = numeral;
                    const asFloat = parseFloat(value)
                    const asNumeral = _numeral(asFloat)
                    const asString = asNumeral.format('0.[000000]')
                    return `${asString} %`
                } catch (e) {
                    return value
                }

            }
            const rg = /(#+)(\.(#*))?/.exec(format)
            if (rg && rg[1]) {
                const pref = rg[1]
                const suf = rg[3] || ''
                let v = Number(value).toFixed(suf.length)
                //zeroPad
                let pad = (pref.length + suf.length) - v.length
                if (pad > 0) {
                    v = '0'.repeat(pad) + v
                }
                return v + '%';
            }


            format = `${format} %`
            return numeral(Number(value)).format(format)
        }
    }catch (e){
        return value;
    }



}
export function breakPath(path:string){
    // path is in the form ubos[0].ubo_nationality - we ned an array of ['ubo',0','ubo_nationality']
    const parts = path.split('.').flatMap((part) => {
        const sIdx = part.indexOf('[')
        if (sIdx > 0) {
            const arr = part.substring(0, sIdx)
            const idx = part.substring(sIdx + 1, part.length - 1)
            return [arr, parseInt(idx)]
        }else{
            return [part]
        }
    });
    return parts;
}

export function pathToDotted(path:string){
    const  parts = breakPath(path);
    return parts.join('.');
}

export function setValue(target,path,value){
    const parts = breakPath(path);
    let v = target;
    let part:string | number;
    for (let i = 0; i < parts.length-1; i++) {
        part = parts[i];
        if (v[part] == null) {
            if (typeof parts[i + 1] == 'number') {
                v[part] = []
            } else {
                v[part] = {}
            }
        }
        v = v[part]
    }
    part = parts[parts.length-1];
    v[part] = value;
}

export function getValue(value:any,path:string){
    const parts = breakPath(path);
    let v = value;
    let part:string | number;
    for (let i = 0; i < parts.length-1; i++) {
        part = parts[i];
        v = v[part]
    }
    part = parts[parts.length-1];
    return v!=null && v[part];
}

export function hasValue(value:any,path:string){
    const parts = breakPath(path);
    let v = value;
    let part:string | number;
    for (let i = 0; i < parts.length-1; i++) {
        part = parts[i];
        v = v[part]
    }
    part = parts[parts.length-1];
    return v!=null && v.hasOwnProperty(part);
}

export const isProxy = Symbol("isProxy")